#SearchBar {
  font-family: "Catamaran";
  font-weight: bold;
  font-size: 19px;
  margin-left: 1px;
  background-color: #D2D2D2;
  border: 1px solid #C38927;
  color: rgba(0, 0, 0, 0.753);
  padding: 7px;
  width: 350px;
}