.summoner-search-section {
  height: 100vh;
  overflow-x: hidden;
  padding-bottom: 10px;

  .container {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  #error {
    text-align: center;
    font-size: 24px;
    margin-top: 100px;
    color: red;
  }

  .headers {
    margin-top: 30px;
    h1 {
      letter-spacing: 0.1em;
      font-weight: bold;
      color: #05212A;
      flex-wrap: wrap;
      font-size: 54px;
    }
  
    h2 {
      letter-spacing: 0.05em;
      font-size: 24px;
      color: #C38927;
      margin: 20px 0;
      max-width: 500px;
    }
  }

  #pengu {
    display: block;
    margin: 0 auto;
  }

  .form-container {
    margin-top: 50px;
    text-align: center;
  }
  
  .card-col {
    margin-top: 30px;
  }

  .card-col > .RankCard {
    margin: 0 auto;
  }
}

@media (max-width: 991px) {
  .summoner-search-section {
    height: initial;
    min-height: 100vh;

    #pengu { 
      margin-right: 0;
    }

    .container {
      position: initial;
      top: initial;
      transform: initial;
    }
  }
}

@media (max-width: 545px) {
  .summoner-search-section {
    text-align: center;

    .search-container {
      margin-top: 20%;
    }

    #pengu { 
      width: 100%;
    }

    .headers {
      h1 {
        font-size: 10vw;
      }

      h2 {
        font-size: 4vw;
      }
    }
  }

  .form-container {
    display: flex;
    flex-direction: row;
  }

  #SearchBar {
    width: 100%;
    order: 2;
  }

  #RegionSelect {
    order: 1;
  }

  #SubmitButton {
    order: 3;
  }
}