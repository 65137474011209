.RankButton {
  color: #05212A;
  border: none;
  background-color: transparent;
  outline: none;
}

.RankButton:hover {
  color: #C38927;
}

.RankButton:focus {
  outline: none;
}