.Footer {
  color: #05212A;
  text-align: center;

  .footer-col {
    border-top: 1px solid #05212A;
    padding: 20px;
  }

  #contact {
    margin-bottom: 0;
  }

  a {
    color: #C38927;
  }
}