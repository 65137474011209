.top-summoners-section {
  margin-top: 20px;
  text-align: center;
  color: #05212A;

  h1 {
    margin-bottom: 15px;
  }

  #error {
    margin: 20px 0;
    font-size: 20px;
    color: red;
  }

  .Container {
    background-color: #d2d2d2;
    border: 2px solid #C38927;
    max-width: 1400px;
  }

  .Header {
    display: flex;
    align-items: center;
    border-bottom: 2px solid #C38927;
    min-height: 44px;

    * {
      font-weight: bold;
    }

    #RegionSelect {
      border: none;
    }
  }
}