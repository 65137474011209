#SubmitButton {
  font-size: 18px;
  font-weight: bold;
  font-family: "Catamaran";
  background-color: #D2D2D2;
  border: 3px solid #C38927;
  margin-left: 5px;
  position: relative;
  height: 44px;
  width: 60px;
}