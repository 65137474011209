body {
  margin: 0;
  padding: 0;
  font-family: "Catamaran", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  background-repeat: no-repeat;
  overflow-y: scroll;
  overflow-x: hidden;
}

button {
  transition: transform 0.3s ease-in-out;
}

button:hover {
  transform: scale(0.9);
}

button:focus {outline:0;}
