.RankCard {
  background-color: #d2d2d2;
  max-width: 600px;
  margin: 30px auto;
  color: #05212A;
  box-shadow: 3px 3px 3px 0px rgba(0,0,0,0.3);

  h2 {
    text-align: center;
    font-size: 24px;
    padding: 8px 0px;
    border-bottom: 2px solid #C38927;
  }

  .RankInfo {
    text-align: center;

    h1 {
      font-size: 38px;
      margin-top: 20px;
    }

    img {
      width: 220px;
      height: 220px;
    }
  }
}

.shine-me {
  width:100%; /*Make sure the animation is over the whole element*/

  animation-name: ShineAnimation;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(.12,.89,.98,.47);
}

@keyframes ShineAnimation{
  from {
      background-repeat:no-repeat;
      background-image:-webkit-linear-gradient(
          top left,
          rgba(255, 255, 255, 0.0) 0%,
          rgba(255, 255, 255, 0.0) 45%,
          rgba(255, 255, 255, 0.5) 48%,
          rgba(255, 255, 255, 0.8) 50%,
          rgba(255, 255, 255, 0.5) 52%,
          rgba(255, 255, 255, 0.0) 57%,
          rgba(255, 255, 255, 0.0) 100%
      );
      background-position:-500px -500px;
      background-size: 700px 700px
  }
  to {
      background-repeat:no-repeat;
      background-position:700px 700px;
  }
}